<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M80-316v-522q0-14 13-28t27-14h519q15 0 28 13.5t13 28.5v356q0 14-13 28t-28 
            14H240L106-306q-8 8-17 4t-9-14Zm201 76q-14 0-27.5-14T240-282v-98h500v-340h100q14 0 27 
            14t13 29v560q0 10-9 14t-17-4L721-240H281Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>