<template>
    <div class="card">
        <div class="flex" @click="go_to_page()">
            <div class="image">
                <img :src="event_prop.image"/>
            </div>
            <div>
                <div class="title">{{ event_prop.title }}</div>
                <div class="description">{{ event_prop.description }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['event_prop', 'paid'],
    methods: {
        go_to_page() {
            this.event_prop['paid'] = this.paid
            localStorage.setItem('event', JSON.stringify(this.event_prop))

            this.$router.push('/event/' + this.event_prop.event_id)
        }
    }
}
</script>

<style scoped>
    .card {
        margin: 15px;
        display: flex;
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
    }
    .flex {
        display: flex;
        align-items: center;
        gap: 20px;
    }
    img {
        width: 80px;
        height: 80px;
        display: block;
    }

    .title {
        font-weight: 500;
    }
    .description {
        font-size: 14px;
        color: gray;
        margin-top: 3px;
    }
</style>