<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 96 960 960">
            <path d="M480 976q-34 0-57.5-23.5T399 895h162q0 34-23.5 57.5T480 976ZM318 
            833v-60h324v60H318Zm5-121q-66-43-104.5-107.5T180 459q0-122 89-211t211-89q122 0 211 89t89 211q0 
            81-38 145.5T637 712H323Z"/>
        </svg>


    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>