<template>
    <div class="white">
        <div class="write" @click="click()">
            <div class="image">
                <img :src="$root.$data.dp"/>
            </div>
            <div>
                <div class="text">
                    What's on your mind {{$root.$data.firstname}}?
                </div>
                <div class="flex">
                    <imageicon :size="'15px'"/>
                    <videoicon :size="'15px'"/>
                    <mention :size="'15px'"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imageicon from '../icons/image.vue'
import videoicon from '../icons/video.vue';
import mention from '../icons/mention.vue';

export default {
    components: {
        imageicon, videoicon, mention
    },
    methods: {
        click() {
            // setTimeout(() => {
                this.$router.push('/create_post')
            // }, 200);
        }
    }
}
</script>

<style scoped>
    .white {
        background-color: white;
        padding: 15px;
        padding-top: 0px;
        padding-bottom: 5px;
    }
    .write {
        padding: 20px;
        /* padding-top: 0px; */
        display: flex;
        align-items: center;
        gap: 20px;
        background-color: #edeef0;
        border-radius: 5px;
        transition: .3s;
    }
    .write:active, .write:focus {
        background-color: #ddd;
    }

    .image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .image img {
        width: 40px;
    }

    .text {
        color: grey;
        font-size: 14px;
        /* font-weight: 500; */
    }
    .flex {
        margin-top: 5px;
        display: flex;
        gap: 10px;
        fill: var(--main);
    }
</style>