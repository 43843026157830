<template>
    <div class="home" ref="container" @touchstart="swipeStart($event)" @touchend="swipeEnd($event)">
        <div class="main">
            <div class="head">
                <div class="logo">
                    <img src="../assets/ikotun_logo_2.png"/>
                </div>
                <div class="options">
                    <div class="opt" v-if="show_install"
                        :class="[this.$root.$data.pop_item == 'home_screen_install' ? '' : 'install']"
                        @click="install_pwa()">
                        <install :size="'18px'" :style="{'height': '18px'}"/>
                    </div>
                    <div class="opt">
                        <search :size="'18px'" :style="{'height': '18px'}"/>
                    </div>
                    <router-link to="/notifications">
                        <div class="opt">
                            <notification :size="'18px'" :style="{'height': '18px'}"/>
                        </div>
                    </router-link>
                    <router-link to="/overview">
                        <div class="opt">
                            <optionicon :size="'18px'" :style="{'height': '18px'}"/>
                        </div>
                    </router-link>
                </div>
            </div>
            
            <write/>

            <div class="icons">

                <div class="grid_item">
                    <div class="icon">
                        <store :size="'20px'"/>
                    </div>
                    <div class="label">Businesses</div>
                </div>
                <div class="grid_item" @click="soon()">
                    <div class="icon">
                        <market :size="'20px'"/>
                    </div>
                    <div class="label">Products</div>
                </div>
                <div class="grid_item" @click="soon()">
                    <div class="icon">
                        <stars :size="'22px'" :style="{'height': '22px'}"/>
                    </div>
                    <div class="label">Experts</div>
                </div>
                <div class="grid_item" @click="soon()">
                    <div class="icon">
                        <zone :size="'20px'"/>
                    </div>
                    <div class="label">Zones</div>
                </div>


                <div class="grid_item" @click="route_to('/elections')">
                    <div class="icon">
                        <election :size="'20px'"/>
                    </div>
                    <div class="label">Elections</div>
                </div>
                <div class="grid_item" @click="route_to('/activity')">
                    <div class="icon">
                        <activity :size="'20px'"/>
                    </div>
                    <div class="label">Activities</div>
                </div>
                <div class="grid_item" @click="route_to('/city/rules')">
                    <div class="icon">
                        <rules :size="'20px'"/>
                    </div>
                    <div class="label">Rules</div>
                </div>                
                <div class="grid_item" @click="route_to('/city/about')">
                    <div class="icon">
                        <about :size="'20px'"/>
                    </div>
                    <div class="label">About</div>
                </div>
            </div>

            <div class="menu">
                <div class="item" :class="[c == 'new_posts' ? 'a' : '']" 
                    @click="get_posts('new_posts')">
                    Newest
                </div>
                <div class="item" :class="[c == 'hot_posts' ? 'a' : '']"
                    @click="get_posts('hot_posts')">
                    Hot
                </div>
            </div>

            <loader class="l" v-if="loading"/>

            <div class="event">
                <event_card v-for="event in events" :key="event.event_id" 
                    :event_prop="event" :paid="paid"/>
            </div>

            <div v-show="c == 'new_posts'">
                <post v-for="post in new_posts" :key="post.id" :post="post"/>
            </div>
            <div v-show="c == 'hot_posts'">
                <post v-for="post in hot_posts" :key="post.id" :post="post"/>
            </div>

        </div>
        <div class="fixed" :style="{'top': fixedTop}">
            <menulayout/>
        </div>
    </div>
</template>

<script>

import pull_to_refresh from '../mixins/pull_to_refresh.js'

import install from '../icons/install.vue'
import search from '../icons/search.vue'
import notification from '../icons/notification.vue'
import optionicon from '../icons/option.vue'

import store from '../icons/store.vue'
import market from '../icons/market.vue'
import stars from '../icons/stars.vue'
import activity from '../icons/activity.vue'
import rules from '../icons/rules.vue'
import zone from '../icons/zone.vue'

import election from '../icons/election.vue'
import about from '../icons/about.vue'

import write from '../components/write.vue'
import loader from '../components/loader.vue'
import post from '../components/post.vue'

import event_card from '../components/event/card.vue'

import menulayout from '../components/menu.vue';

export default {
    
    mixins: [pull_to_refresh],
    name: 'Home',
    components: {
        install, search, notification, optionicon,
        store, market, stars, activity, rules, zone,
        election, about,
        write, 
        loader,
        post,
        event_card,
        menulayout
    },
    data() {
        return {

            fixedTop: '',


            c: 'new_posts',
            new_posts: [],
            hot_posts: [],
            events: [],
            paid: false,

            loading: true,

            config: {
                errorLabel: 'error',
                startLabel: 'start',
                readyLabel: 'Refresh',
                loadingLabel: 'loading',
                pullDownHeight: 20
            },

        }
    },
    computed: {
        show_install() {
            return this.$root.$data.pwa_install_prompt
        }
    },
    mounted() {
        this.get_posts('new_posts')

        window.addEventListener('resize', this.setTop)
        this.setTop()
    },
    unmounted() {
        window.removeEventListener('resize', this.setTop);
    },
    methods: {

        setTop() {
            this.fixedTop = (window.innerHeight - 60) + "px"
        },

        install_pwa() {

            this.$root.$data.pop = true
            this.$root.$data.pop_item = 'home_screen_install'
        },


        refresh() {
            this[this.c] = []
            this.events = []

            this.get_posts(this.c)
        },
        route_to(where) {
            this.$router.push(where)
        },
        get_posts(where) {
            this.c = where
            if (this[where].length != 0) {
                return
            }

            this.loading = true

            let form = new FormData()
            form.append('where', where.split('_')[0])
            form.append('date', '')

            this.$http.post('/post/get', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.paid = r.paid
                    this.events = r.events
                    this[where] = this[where].concat(r.posts)
                }
                this.loading = false
            })
        },
        soon() {
            this.coming_soon()
        }
    },
    notifications: {
        coming_soon: {
            type: 'info',
            title: 'Coming Soon',
            message: 'This feature will be available in the next update'
        }
    }
}
</script>


<style scoped>
    .home {
        /* position: relative; */
        height: 100%;
        overflow: auto;
        background-color: #edeef0;
    }
    .main {
        position: relative;
        padding-bottom: 100px;
        /* height: calc(100vh - 60px); */
    }

    .head {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
    }
    .options {
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .opt {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--trans);
        fill: var(--main);
        transition: .3s;
    }
    .opt:active, .opt:focus {
        background-color: var(--main);
        fill: white;
    }
    .install {
        animation: blinking 1s infinite linear;
    }
    @keyframes blinking {
        0%, 100% {
            transform: scale(1);
            background-color: var(--trans);
            fill: var(--main);
        }
        50% {
            transform: scale(1.2);
            background-color: var(--main);
            fill: white;
        }
    }

    .logo img {
        width: 100px;
    }

    

    .icons {
        display: grid;
        grid-template-columns: calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
        gap: 30px 20px;
        padding: 20px 20px;
        background-color: white;
    }
    .icon {
        text-align: center;
        fill: var(--main);
        margin: 0 auto;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--trans);
        transition: .3s;
    }
    .icon:active {
        background-color: var(--main);
        color: var(--main);
        fill: white;
    }
    .label {
        font-size: 12px;
        font-weight: 500;
        margin-top: 3px;
        text-align: center;
    }


    .menu {
        display: grid;
        grid-template-columns: 50% 50%;
        border-bottom: 1px solid #ddd;
        background-color: white;
        position: sticky;
        z-index: 2;
        top: 0px;
    }
    .item {
        text-align: center;
        padding: 15px 0px;
        border-bottom: 2px solid transparent;
        font-size: 14px;
        transition: .3s;
    }
    .item:active, .item:focus {
        background-color: var(--trans);
    }
    .a {
        font-weight: 500;
        border-bottom-color: var(--main);
        color: var(--main);
    }

    .l {
        margin: 100px 0px;
    }

    .fixed {
        position: absolute;
        z-index: 2;
        /* top: 200px; */
        /* left: 0px; */
        width: 100%;
        max-width: var(--max-width);
        margin: 0 auto;
    }
</style>